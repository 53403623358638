export enum ActivityType {
  Person = 'person',
  Organisation = 'organisation',
}

export enum GENDER {
  MALE = 'M',
  FEMALE = 'F',
  UNKNOWN = 'U',
}

export enum ChatMessageType {
  Text = 'text',
  Notification = 'notification',
}

export enum OptionType {
  WAZO = 'wazo',
  VANGNET = 'vangnet',
  OTHER = 'other',
}

export enum ActionListTypes {
  Contractor = 'contractor',
  Employer = 'employer',
  Division = 'division',
  Department = 'department',
  Person = 'person',
}

export enum SEARCH_TYPE {
  INTELLIGENT = 'intelligent',
  ADVANCED = 'advanced',
}

export enum TASK_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  HALT = 'halt',
  NO_SHOW = 'no_show',
}

export enum ActionButtonType {
  LINK = 'link',
  CONFIRM = 'confirm',
  MODAL = 'modal',
  VIDEO = 'video',
}

export enum TemplateType {
  SURVEY = 'survey',
  FORM = 'form',
  EMAIL = 'email',
  SMS = 'sms',
}

export enum NOTIFICATION_TYPE {
  NOTIFICATION = 'notification',
  ALERT = 'alert',
}

export enum OnlineStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  AWAY = 'away',
}

export enum UserType {
  ADMIN = 'admin',
  PROFESSIONAL = 'professional',
  EMPLOYER = 'employer',
  PERSON = 'person',
}

export enum LanguageEnum {
  NL = 'nl',
  EN = 'en',
}
