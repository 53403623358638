import { getAuthCookie } from '~/utils/auth-cookie'
import moment from 'moment'
import {
  AuthHeader,
  Gender,
  Organization,
  PaginationResponse,
  RelationshipStatus,
  TaskStatus,
  TimelineType,
} from '~/ts/globals'
import { LanguageEnum } from '~/ts/enums'

export const APP_NAME: string = import.meta.env.VITE_APP_NAME || 'Ainsights'
export const API_URL: string = import.meta.env.VITE_API_URL
export const BROADCAST_URL: string = import.meta.env.VITE_BROADCAST_URL
export const GMAPS_API_KEY = 'AIzaSyB5-CIGmIXYwcAsGoB-86gpMfYQNCw9Mwo'

export const apiUrl = () => {
  const url = new URL(import.meta.env.VITE_API_URL)
  return url.origin
}

export const AUTH_HEADER: AuthHeader = {
  Accept: 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${getAuthCookie()}`,
  Locale: window.localStorage.getItem('lang') || 'en',
}

export const GENDER: Gender[] = [
  { name: 'Male', value: 'M' },
  { name: 'Female', value: 'F' },
  { name: 'Unknown', value: 'U' },
]

export const defaultCountryId: number = 73

export const NlPostalCodeTokens: string =
  "{'W': { 'pattern': '[1-9]' }, 'P': { 'pattern': '[0-9]' }, 'A': { 'pattern': '[a-zA-Z]', 'transform': (chr) => chr.toUpperCase() }}"

export const sortIcons: {
  sort: string
  sortAsc: string
  sortDesc: string
} = {
  sort: 'fa-sort',
  sortAsc: 'fa-sort-up',
  sortDesc: 'fa-sort-down',
}

export const formatMoney = (
  value: number,
  currency: string = 'EUR',
  lang: string = 'nl-NL'
) => {
  return new Intl.NumberFormat(lang, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  }).format(value)
}

export const formatDate = (date: string | Date): string => {
  const momentDate = moment(date, 'YYYY-MM-DD')
  if (momentDate.isValid()) {
    return momentDate.format('DD MMM, YYYY')
  }
  return ''
}

export const formatDateTime = (datetime: string | Date): string => {
  const momentDate = moment(datetime, 'YYYY-MM-DD HH:mm:ss')
  if (momentDate.isValid()) {
    return momentDate.format('DD MMM, YYYY - HH:mm')
  }
  return ''
}

export const link = (link: string): string => {
  return `
    <router-link
      v-if="link"
      :to="${link}"
    >
      <Icon icon="mdi:link" class="text-xl mr-2" />
      <span class="text-sm">view</span>
    </router-link>
  `
}

export const renderConcluded = (data: string): string => {
  return data
    ? `<div class="text-center">
        <span class="inline-block px-2 py-1 text-white bg-green-500 rounded-lg">
          <i class="fas fa-check-circle"></i> Concluded
        </span>
      </div>`
    : `<div class="text-center">-</div>`
}

export const calculateProgress = (deadline: string): string => {
  const deadlineDate = moment(deadline, 'YYYY-MM-DD')
  const currentDate = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')
  const daysDifference = deadlineDate.diff(currentDate, 'days')
  let backgroundColor: string
  let buttonText: string
  if (daysDifference > 5) {
    backgroundColor = 'bg-green-500'
    buttonText = 'On Time'
  } else if (daysDifference < 5 && daysDifference > 0) {
    backgroundColor = 'bg-yellow-500'
    buttonText = 'Warning'
  } else {
    buttonText = 'Overdue'
    backgroundColor = 'bg-red-500'
  }
  return `<button class="text-white text-xs px-3 py-1 rounded-full ${backgroundColor}"><b>${buttonText}</b></button>`
}

export const formatDateTimeToTime = (datetime: string | Date): string => {
  const momentDate = moment(datetime, 'YYYY-MM-DD HH:mm:ss')
  if (momentDate.isValid()) {
    return momentDate.format('HH:mm')
  }
  return ''
}

export const formatTime = (datetime: string | Date): string => {
  const momentDate = moment(datetime, 'HH:mm:ss')
  if (momentDate.isValid()) {
    return momentDate.format('HH:mm')
  }
  return ''
}

export const secondsToMinutes = (data: number): number => {
  return Math.floor(data / 60)
}

export const timelineTypes: TimelineType[] = [
  { name: 'Absence History', value: 'App\\Models\\Absence\\AbsenceHistory' },
  { name: 'Appointment', value: 'App\\Models\\Appointment' },
  { name: 'Profile Information', value: 'App\\Models\\Users\\FileUser' },
  { name: 'Employment', value: 'App\\Models\\Employment' },
]

export const paginationResponse = <T>(data: T[]): PaginationResponse<T> => {
  return {
    current_page: 1,
    data,
    first_page_url: '',
    from: 1,
    last_page: 1,
    last_page_url: '',
    links: [],
    next_page_url: null,
    path: '',
    per_page: 10,
    prev_page_url: null,
    to: 1,
    total: 1,
  }
}

export const generateBase64Image = (
  alphabet: string,
  height: number = 100,
  width: number = 100
): string => {
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext('2d')

  if (ctx) {
    // Draw the alphabet on the canvas
    ctx.fillStyle = '#f5f5f5'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    ctx.fillStyle = 'black'
    ctx.font = '50px Arial'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillText(alphabet, canvas.width / 2, canvas.height / 2)

    // Convert the canvas to a base64 image string
    return canvas.toDataURL('image/png')
  }

  return ''
}

export const getUserNameInitials = (name: string | undefined) => {
  if (!name) return 'U'
  const [firstName, lastName] = name.split(' ')
  return (
    (firstName ? firstName.charAt(0) : '') +
    (lastName ? lastName.charAt(0) : '')
  )
}

export const menuItems = [
  {
    name: 'Dashboard',
    icon: 'material-symbols:dashboard',
    href: '/',
    isMainMenuItem: true,
  },
  {
    name: 'Tasks',
    icon: 'fluent:tasks-app-24-filled',
    href: '/tasks',
    isMainMenuItem: true,
  },
  {
    name: 'Appointments',
    icon: 'material-symbols:event-note',
    href: '/appointments',
    isMainMenuItem: true,
  },
  {
    name: 'Documents',
    icon: 'solar:documents-bold',
    href: '/documents',
  },
  {
    name: 'Employment',
    icon: 'clarity:employee-solid',
    href: '/employment',
    isMainMenuItem: true,
  },
  {
    name: 'Timeline',
    icon: 'material-symbols:view-timeline',
    href: '/timeline',
    isMainMenuItem: true,
  },
  {
    name: 'Contacts',
    icon: 'ri:contacts-book-2-fill',
    href: '/chat',
  },
  {
    name: 'Inbox',
    icon: 'material-symbols:inbox',
    href: '/inbox',
  },
  {
    name: 'Helpdesk',
    icon: 'material-symbols:help',
    href: '/helpdesk',
  },
  {
    name: 'Contacts',
    icon: 'streamline:contact-phonebook-2-solid',
    href: '/contacts',
    isMainMenuItem: true,
  },
  {
    name: 'Projects',
    icon: 'streamline:clipboard-check-solid',
    href: '/projects',
    isMainMenuItem: true,
  },
]

export const RELATIONSHIP_STATUSES: RelationshipStatus[] = [
  { value: 'single', name: 'Single' },
  { value: 'in_a_relationship', name: 'In a Relationship' },
  { value: 'married', name: 'Married' },
  { value: 'civil_partnership', name: 'Civil Partnership' },
  { value: 'cohabiting', name: 'Cohabiting' },
  { value: 'separated', name: 'Separated' },
  { value: 'divorced', name: 'Divorced' },
  { value: 'widowed', name: 'Widowed' },
  { value: 'engaged', name: 'Engaged' },
  { value: 'prefer_not_to_say', name: 'Prefer Not To Say' },
  { value: 'other', name: 'Other' },
]

export const renderGender = (gender: string): string => {
  if (gender === 'M') {
    return 'Male'
  } else if (gender === 'F') {
    return 'Female'
  } else {
    return 'Unknown'
  }
}

export const renderRecordType = (recordType: string): string => {
  return recordType
}

export function chunkArray<T>(array: T[], size: number): T[][] {
  const result: T[][] = []
  for (let i = 0; i < array.length; i += size) {
    const subArray: T[] = array.slice(i, i + size)
    result.push(subArray)
  }
  return result
}

export const TASK_STATUS: TaskStatus[] = [
  { value: 'pending', name: 'Pending' },
  { value: 'completed', name: 'Completed' },
  { value: 'cancelled', name: 'Cancelled' },
  { value: 'halt', name: 'Halt' },
  { value: 'no_show', name: 'NoShow' },
]

export const badge = (value: string): string => {
  return `<span class="bg-blue-500 text-white py-1 px-2 rounded">${value}</span>  `
}

export const capitalize = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const languageOptions = [
  { name: 'English', value: LanguageEnum.EN },
  { name: 'Dutch', value: LanguageEnum.NL },
]

export const organizationNamedata = (obj: Organization): string => {
  if (obj.organization_name) {
    return obj.organization_name
  } else if (obj.name) {
    return obj.name
  } else if (obj.user_type === 'department') {
    return obj.department_name
  } else if (obj.user_type === 'division') {
    return obj.division_name
  }
  return ''
}
