import { defineStore } from 'pinia'
import {
  getAuthCookie,
  setAuthCookie,
  removeAuthCookie,
} from '~/utils/auth-cookie'
import AuthApi from '~/api/AuthApi'
import { User } from '~/ts/interfaces/models/user.interface'
import { AuthToken } from '~/ts/interfaces/services/auth.service'
import { FileDetailService } from '~/ts/interfaces/services/fileDetail.service'
import { formatDate, renderGender } from '~/utils/constants'

interface AuthState {
  authUser: User | null
  bearerToken: string | null
  isLoggedIn: boolean
  personDetail: FileDetailService
  kickOut: boolean
  termsVersion: number
  policyVersion: number
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    authUser: null,
    bearerToken: getAuthCookie(),
    isLoggedIn: !!getAuthCookie(),
    personDetail: {} as FileDetailService,
    kickOut: false,
    termsVersion: 0,
    policyVersion: 0,
  }),

  actions: {
    async fetchAuthUser() {
      await AuthApi.authUser()
        .then(response => {
          this.authUser = response
          this.setPersonDetail(this.authUser)
        })
        .catch(() => {
          this.resetAuthUser()
        })
    },

    async fetchTermsAndPolicyVersion(): Promise<void> {
      await AuthApi.termsAndPolicyVersion().then(response => {
        this.termsVersion = response.terms_version
        this.policyVersion = response.policy_version
      })
    },

    setAuthUser(data: AuthToken) {
      this.authUser = data.user

      setAuthCookie(data.token)
      this.setPersonDetail(data.user)
      this.bearerToken = data.token
      this.isLoggedIn = !!data.token
      this.authUser = data.user
    },

    setPersonDetail(person: User) {
      this.personDetail.absence_reporting_status =
        person?.employment?.contractor?.employees_absence_reporting_status
      this.personDetail.name = person.name
      this.personDetail.email = person.email
      this.personDetail.gender = person.gender!
      if (person.image) {
        this.personDetail.avatar = person.image
      } else if (person.gender === 'M') {
        this.personDetail.avatar = '/static/images/man.png'
      } else if (person.gender === 'F') {
        this.personDetail.avatar = '/static/images/woman.png'
      } else {
        this.personDetail.avatar = '/static/images/transgender.png'
      }
      this.personDetail.button = {
        text: 'Edit',
        link: `/person-information`,
      }
      this.personDetail.notes = {
        columnSpan: 4,
        text: person.notes || '',
      }
      this.personDetail.profileData = [
        [
          {
            icon: 'tabler:calendar',
            label: 'DOB',
            value: formatDate(person.dob!),
          },
          {
            icon: 'tabler:gender-bigender',
            label: 'Gender',
            value: renderGender(person.gender!),
          },
        ],
        [
          {
            icon: 'tabler:fingerprint',
            label: 'SSN',
            value: person.ssn!,
          },
          {
            icon: 'fa6-regular:address-card',
            label: 'Address',
            value: person.full_address!,
          },
        ],
        [
          {
            icon: 'tabler:building-community',
            label: 'City',
            value: person.postal_city!,
          },
          {
            icon: 'tabler:mailbox',
            label: 'Postal Code',
            value: person.postal_postal_code!,
          },
        ],
      ]
    },

    resetAuthUser() {
      this.authUser = null
      this.bearerToken = null
      this.isLoggedIn = false
      removeAuthCookie()

      this.kickOut = true
    },
  },
})
