import '~/assets/style.css'
import { app, router } from '~/boot'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useAuthStore } from '~/stores/AuthStore'

app.mount('#app')

/**
 * Navigation Guard
 */
router.beforeEach(
  (
    to: RouteLocationNormalized,
    _: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (!to.matched.length) {
      next({ name: '404' })
    }

    const authStore = useAuthStore()

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!authStore.isLoggedIn) {
        router.push('/auth/login').then(r => r)
      }
    } else {
      if (authStore.isLoggedIn) {
        router.push('/').then(r => r)
      }
    }

    next()
  }
)
