import { $http } from '~/boot/plugins/interceptors'
import { User } from '~/ts/interfaces/models/user.interface'
import type {
  AuthToken,
  LoginResponse,
} from '~/ts/interfaces/services/auth.service'

type Message = { message: string }

export default {
  async login(payload: object): Promise<LoginResponse> {
    const { data } = await $http.post<LoginResponse>(`/login`, payload)
    return data
  },

  async twoFA(payload: object): Promise<AuthToken> {
    const { data } = await $http.post<AuthToken>(`/google2fa`, payload)
    return data
  },

  async forgotPassword(payload: object): Promise<Message> {
    const { data } = await $http.post<Message>(`/forgot-password`, payload)
    return data
  },

  async resetPassword(payload: object): Promise<Message> {
    const { data } = await $http.post<Message>(`/reset-password`, payload)
    return data
  },

  async changePassword(payload: object): Promise<Message> {
    const { data } = await $http.post<Message>(`/change-password`, payload)
    return data
  },

  async register(payload: object): Promise<User> {
    const { data } = await $http.post<User>(`/register`, payload)
    return data
  },

  async resendVerificationLink(
    personId: string,
    payload = null
  ): Promise<Message> {
    const { data } = await $http.post<Message>(
      `/email/resend-verification/${personId}`,
      payload
    )
    return data
  },

  async authUser(params = undefined): Promise<User> {
    const { data } = await $http.get<User>(`/auth-user`, params)
    return data
  },

  async logout(): Promise<void> {
    const { data } = await $http.post<void>(`/logout`)
    return data
  },

  async termsAndPolicyVersion() {
    const { data } = await $http.get<{
      terms_version: number
      policy_version: number
    }>(`/settings/terms-and-policy-version`)
    return data
  },
}
