// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { createRouter, createWebHistory } from 'vue-router/auto'

/**
 * Register router
 */
export default createRouter({
  history: createWebHistory(),
})
