import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { BROADCAST_URL } from '~/utils/constants'
import { getAuthCookie } from '~/utils/auth-cookie'

/**
 * Laravel Echo (Pusher)
 */
const laravelEcho: Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY || 'app-key',
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER || 'app-cluster',
  wsHost: import.meta.env.VITE_PUSHER_APP_HOST || 'localhost',
  wsPort: import.meta.env.VITE_PUSHER_APP_PORT || 6001,
  wssPort: import.meta.env.VITE_PUSHER_APP_PORT || 6001,
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  namespace: 'App\\Events\\Broadcast',
  authEndpoint: BROADCAST_URL,
  auth: {
    headers: {
      Authorization: `Bearer ${getAuthCookie()}`,
    },
  },
})

if (import.meta.env.VITE_APP_ENV === 'development') {
  Pusher.logToConsole = true
}

export { laravelEcho, Pusher }
