import { createApp } from 'vue'
import App from '~/App.vue'
import {
  router,
  pinia,
  i18n,
  interceptors,
  toast,
  toastOptions,
  surveyPlugin,
  vSelect,
  vModal,
  vueQuery,
  apexCharts,
} from './plugins'
import { laravelEcho, pusher } from './providers'
import 'vue-loading-overlay/dist/css/index.css'

export const app = createApp(App)

/**
 * Register plugins
 */
app.use(toast, toastOptions)
app.use(pinia)
app.use(router)
app.use(i18n)
app.use(vueQuery)
app.use(interceptors)
app.use(vModal, {
  teleportTarget: '#modals',
})
app.use(surveyPlugin)
app.use(apexCharts)

/**
 * Register providers
 */
app.provide('$echo', laravelEcho)
app.provide('$pusher', pusher)

/**
 * Register global components
 */
app.component('VSelect', vSelect)

export { router }
